<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import JsonExcel from "vue-json-excel";
import store from "@/state/store";

export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 1 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Leave Tracker Report",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    JsonExcel,
  },
  mounted() {
    this.refreshTable();
  },
  methods: {
    refreshTable() {
      this.period = this.$route.params.period;
      this.period = this.period.split("-");
      this.location = this.$route.params.locationID;
      let data = {
        month: parseFloat(this.period[0]),
        year: parseFloat(this.period[1]),
        location: parseFloat(this.location),
      };

      const url = `${this.ROUTES.leaveApplication}/leave-application-tracking-report`;
      this.apiPost(url, data, "Generate Leave Tracker Report").then((res) => {
        const { data } = res;
        console.log({ data });
        data.forEach((leaveTracker, index) => {
          let leaveTrackerObj = {
            sn: ++index,
            d7: leaveTracker.d7,
            t7: leaveTracker.t7,
            fullName: `${leaveTracker.first_name}
            ${leaveTracker.other_name ? leaveTracker.other_name : ""}
            ${leaveTracker.last_name}`,
            jobTitle: leaveTracker.jobTitle,
            t3: leaveTracker.t3,
            t6: leaveTracker.t6,
            contractType: leaveTracker.contractType,
            contractHireDate: leaveTracker.contractHireDate
              ? new Date(leaveTracker.contractHireDate).toDateString()
              : "",
            contractEndDate: leaveTracker.contractEndDate
              ? new Date(leaveTracker.contractEndDate).toDateString()
              : "",
            annualLeaveRate: leaveTracker.annualLeaveRate,
            annualLeaveUsed: leaveTracker.annualLeaveUsed,
            annualLeaveEndingBalance: leaveTracker.annualLeaveBalEnding,
            annualLeaveEndingBalanceOfFY: leaveTracker.annualLeaveBalEndingFy,
            sickLeaveUsed: leaveTracker.sickLeaveUsed,
            sickLeaveBalEnding: leaveTracker.sickLeaveBalEnding,
            sickLeaveBalance: leaveTracker.sickLeaveBalance,
            percentageAnnualLeaveUsed: leaveTracker.percentageAnnualLeaveUsed,
            month: new Date(
              `${leaveTracker.month.toString()} 1 ${leaveTracker.year.toString()}`
            ).toLocaleDateString("default", {
              month: "long",
            }),
            year: leaveTracker.year,
          };
          this.leaveTrackers.push(leaveTrackerObj);
        });
        this.filtered = this.leaveTrackers;
        this.totalRows = this.leaveTrackers.length;
        if (this.location === 0) {
          this.locationName = "All Locations";
        }
      });

      this.newFields.forEach((field) => {
        let key = field;
        if (key === "sn") {
          this.jsonFields["S/N"] = key;
        } else if (key === "d7") {
          this.jsonFields["D7"] = key;
        } else if (key === "t7") {
          this.jsonFields["T7"] = key;
        } else if (key === "fullName") {
          this.jsonFields["FULL NAME"] = key;
        } else if (key === "jobTitle") {
          this.jsonFields["JOB TITLE"] = key;
        } else if (key === "t3") {
          this.jsonFields["T3 CODE"] = key;
        } else if (key === "t6") {
          this.jsonFields["T6 CODE"] = key;
        } else if (key === "contractType") {
          this.jsonFields["CONTRACT TYPE"] = key;
        } else if (key === "contractHireDate") {
          this.jsonFields["CONTRACT HIRE DATE"] = key;
        } else if (key === "contractEndDate") {
          this.jsonFields["CONTRACT END DATE"] = key;
        } else if (key === "annualLeaveRate") {
          this.jsonFields["ANNUAL LEAVE RATE"] = key;
        } else if (key === "annualLeaveUsed") {
          this.jsonFields["ANNUAL LEAVE USED"] = key;
        } else if (key === "annualLeaveEndingBalance") {
          this.jsonFields["ANNUAL LEAVE ENDING BALANCE"] = key;
        } else if (key === "annualLeaveEndingBalanceOfFY") {
          this.jsonFields["ANNUAL LEAVE ENDING BALANCE OF FY"] = key;
        } else if (key === "sickLeaveUsed") {
          this.jsonFields["SICK LEAVE USED"] = key;
        } else if (key === "sickLeaveBalEnding") {
          this.jsonFields["SICK LEAVE ENDING BALANCE"] = key;
        } else if (key === "sickLeaveBalance") {
          this.jsonFields["SICK LEAVE ENDING BALANCE OF FY"] = key;
        } else if (key === "percentageAnnualLeaveUsed") {
          this.jsonFields["PERCENTAGE USED ANNUAL LEAVE"] = key;
        } else if (key === "month") {
          this.jsonFields["MONTH"] = key;
        } else if (key === "year") {
          this.jsonFields["YEAR"] = key;
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.filtered = filteredItems;
      this.currentPage = 1;
    },
  },
  data() {
    return {
      title: "Leave Tracker Report",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Leave Tracker Report",
          active: true,
        },
      ],
      period: null,
      filtered: [],
      leaveTrackers: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      newFields: [
        "sn",
        "d7",
        "t7",
        "fullName",
        "jobTitle",
        "t3",
        "t6",
        "contractType",
        "contractHireDate",
        "contractEndDate",
        "annualLeaveRate",
        "annualLeaveUsed",
        "annualLeaveEndingBalance",
        "annualLeaveEndingBalanceOfFY",
        "sickLeaveUsed",
        "sickLeaveBalEnding",
        "sickLeaveBalance",
        "percentageAnnualLeaveUsed",
        "month",
        "year",
      ],
      jsonFields: {},
      location: null,
      locationName: null,
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="d-flex justify-content-end mb-3">
      <b-button class="btn btn-success" @click="$router.push('/reports')">
        <i class="mdi mdi-plus mr-2"></i>
        Reports
      </b-button>
    </div>
    <scale-loader v-if="apiBusy" />
    <div v-else class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="p-3 bg-light mb-4 d-flex justify-content-between">
              <h5 class="font-size-14 mb-0" v-if="period">
                Leave Tracker Report for
                {{ locationName }} in Period:
                {{ (parseInt(period[0]) - 1) | getMonth }}
                {{ period[1] }}
              </h5>
              <span class="font-size-12 text-success" v-if="period">
                <JsonExcel
                  style="cursor: pointer"
                  :data="leaveTrackers"
                  :fields="jsonFields"
                  :name="`Leave_Tracker_Report_(${period[0]}-${period[1]}).xls`"
                >
                  Export to Excel
                </JsonExcel>
              </span>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!--              <div class="col-sm-12 col-md-3 text-md-right">-->
              <!--                <b-form-group-->
              <!--                  label="Filter On"-->
              <!--                  label-cols-sm="7"-->
              <!--                  label-align-sm="right"-->
              <!--                  label-size="sm"-->
              <!--                  class="mb-0"-->
              <!--                  v-slot="{ ariaDescribedby }"-->
              <!--                >-->
              <!--                  <b-form-checkbox-group-->
              <!--                    v-model="filterOn"-->
              <!--                    :aria-describedby="ariaDescribedby"-->
              <!--                    class="mt-1"-->
              <!--                  >-->
              <!--                    <b-form-checkbox value="location">Location</b-form-checkbox>-->
              <!--                  </b-form-checkbox-group>-->
              <!--                </b-form-group>-->
              <!--              </div>-->
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0" v-if="leaveTrackers.length">
              <b-table
                ref="deduction-table"
                bordered
                hover
                small
                :items="leaveTrackers"
                :fields="newFields"
                striped
                responsive="lg"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
              >
                <template #cell(sn)="row">
                  <span>
                    {{ row.value }}
                  </span>
                </template>
                <template #cell(t7)="row">
                  <span>
                    {{ row.value }}
                  </span>
                </template>
                <template #cell(d7)="row">
                  <span>
                    {{ row.value }}
                  </span>
                </template>
                <template #cell(employeeName)="row">
                  <span class="text-nowrap">
                    {{ row.value }}
                  </span>
                </template>
                <template #cell(sector)="row">
                  <span class="text-nowrap">
                    {{ row.value }}
                  </span>
                </template>

                <template #cell(location)="row">
                  <span class="text-nowrap">
                    {{ row.value }}
                  </span>
                </template>

                <!--                <template #cell(month)="row">-->
                <!--                  <span class="text-nowrap">-->
                <!--                    {{ (parseInt(row.value) - 1) | getMonth }}-->
                <!--                  </span>-->
                <!--                </template>-->
                <!--                <template #cell(year)="row">-->
                <!--                  <span class="text-nowrap">-->
                <!--                    {{ row.value }}-->
                <!--                  </span>-->
                <!--                </template>-->

                <template #cell(employee_gross)="row">
                  <span class="text-nowrap">
                    {{ row.value }}
                  </span>
                </template>

                <template #cell(pension_employee_contribution)="row">
                  <span class="text-nowrap">
                    {{ row.value }}
                  </span>
                </template>
                <template #cell(pension_employee_contribution)="row">
                  <span class="text-nowrap">
                    {{ row.value }}
                  </span>
                </template>
                <template #cell(voluntary_pension)="row">
                  <span class="text-nowrap">
                    {{ row.value }}
                  </span>
                </template>

                <template #cell(total)="row">
                  <span class="text-nowrap">
                    {{ row.value }}
                  </span>
                </template>
                <template #cell(pfa)="row">
                  <span class="text-nowrap">
                    {{ row.value }}
                  </span>
                </template>
                <template #cell(pension_number)="row">
                  <span class="text-nowrap">
                    {{ row.value }}
                  </span>
                </template>
              </b-table>
            </div>
            <div v-else>
              <p class="text-center my-5">
                Populating report table, please wait...
              </p>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
